<template>
    <div class="default-site-layout">
        <DelayHydration>
            <AppHeader />
        </DelayHydration>

        <div class="default-site-layout__header-and-outlet-separator"></div>

        <div class="default-site-layout__nuxt-outlet">
            <slot />
        </div>

        <LayoutDialogsWrapper />
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

await preloadComponents('AppHeader')

const RootStore = useRootStore()

const adbarsDesktop = RootStore.layoutData?.data.header.adbar?.desktop

const adbarsMobile = RootStore.layoutData?.data.header.adbar?.mobile

useHead({
    bodyAttrs: {
        class:
            (RootStore.layoutData?.data.header.adbar ? 'add-adbars-height' : '') +
            (adbarsDesktop ? ' show-desktop-adbars' : '') +
            (adbarsMobile ? ' show-mobile-adbars' : ''),
    },
})
const app = useNuxtApp()

app.hook('page:finish', () => {
    document.documentElement.scrollTop = 0
})
</script>

<style lang="postcss">
.default-site-layout {
    @apply relative flex flex-col justify-between pb-20;

    --header-menu-height: theme('spacing.14');
    --headband-height: theme('spacing.8');
    --total-header-height: calc(
        var(--notification-height, 0rem) + var(--layout-header-adbar-height, 0rem) +
            var(--header-menu-height, 0rem) + var(--headband-height, 0rem)
    );

    @apply min-h-screen overflow-x-hidden bg-site-background;

    &__nuxt-outlet {
        @apply relative flex flex-grow flex-col transition-[margin-top] duration-300;

        min-height: calc(100vh - var(--total-header-height));

        > * {
            @apply flex-grow;
        }
    }

    &__header-and-outlet-separator {
        @apply block h-0 w-full transition-[margin-top] duration-300;

        margin-top: var(--total-header-height, 0rem);
    }
}

@screen lg {
    .default-site-layout {
        --header-menu-height: theme('spacing.16');
    }
}
</style>
